<template>
  <BaseLayout />
</template>
<style src="./assets/css/style.css"></style>
<script>
import BaseLayout from "./layouts/base.vue";

export default {
  methods: {
  },
  name: 'App',
  components: {
    BaseLayout,
  }
}
</script>
