<template>
    <div id="sidebar">
        <div :class="rightSidebarToggle == false ? 'right-navbar' : ''"
            style="background-color: #161B22; border-bottom: 1px solid #21262D !important" class="row py-3 d-flex bg-dark">
            <div v-if="rightSidebarToggle == false"
                class="col-12 px-3 d-flex align-items-center justify-content-between pe-3">
                <!-- <a class="navbar-brand" href="#"> -->
                <h3 class=" right-sidebar-heading">Stored Values</h3>
                <!-- </a> -->
                <a @click="handleRightSidebar()" id="sidebar-toggler-btn">
                    <img src="../assets/expand.svg" alt="">
                </a>
            </div>
        </div>
        <div v-if="rightSidebarToggle == false" class="px-3">
            <div class="row">
                <div class="col-12 pt-5">
                    <p class="sidebar-title-recently"></p>
                </div>
                <div class="col-12">
                    <div class=" mt-5">
                        <div class="row">
                            <div class="col-12">
                                <div class="row px-3 pt-3" v-if="companies">
                                    <span v-for="(company, index) in companies" :key="index">

                                        <div class="col-12">
                                            <p class="right-sidebar-company-name">
                                                Company Name
                                            </p>
                                        </div>
                                        <div class="col-12">
                                            <p class="company-name-response">
                                                {{ company.name }}

                                            </p>
                                        </div>
                                        <div class="col-12">
                                            <p class="right-sidebar-keyword">
                                                List of keywords
                                            </p>
                                        </div>
                                        <div class="col-12">
                                            <p class="company-name-response">
                                                {{ data.keywords }}
                                            </p>
                                        </div>
                                    </span>
                                </div>
                                <div class="row  px-3 my-3">
                                    <div class="col-12">
                                        <!-- <div>
                                            <ProgressSpinner style="width: 33px;
                                        height: 50px;
                                        display: flex;
                                        position: absolute;
                                        margin-left: 90px !important;
                                        margin-top: 46px;;" strokeWidth="8" fill="var(--surface-ground)"
                                                animationDuration=".5s" aria-label="Custom ProgressSpinner" />
                                        </div> -->
                                        <span>
                                            <span>Competitor Prompt</span> <i class="bi bi-pencil-square"
                                                @click="viewCompetitorPrompt()"></i>
                                            <div class="form-group" v-if="isCompetitorPrompt === true">
                                                <textarea @input="updateCompetitorPromptInput()" class="form-control"
                                                    id="exampleFormControlTextarea1" rows="3"
                                                    v-if="isCompetitorPrompt == true && promptCompetitors.length > 0"
                                                    v-model="promptCompetitors"></textarea>
                                                <button class="btn btn-sm btn-success mt-2"
                                                    :disabled="requestSteps.disabledPrompt == true"
                                                    @click="updateCompetitorPrompt()">
                                                    Update Competitor Prompt
                                                </button>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div class="row  px-3 my-3" v-if="getKeywordsPrompt">
                                    <div class="col-12">
                                        <span>
                                            <span>Keywords Prompt</span> <i class="bi bi-pencil-square"
                                                @click="viewKeywordsPrompt()"></i>
                                            <div class="form-group" v-if="isKeywordsPrompt === true">
                                                <textarea @input="updateKeywordsPromptInput()" class="form-control"
                                                    id="exampleFormControlTextarea1" rows="3"
                                                    v-if="isKeywordsPrompt == true && promptKeywords.length > 0"
                                                    v-model="promptKeywords"></textarea>
                                                <button class="btn btn-sm btn-success mt-2"
                                                    :disabled="requestSteps.disabledPrompt == true"
                                                    @click="promptUpdateKeywords()">Update Keywords
                                                    Prompt</button>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div class="row  px-3 my-3" v-if="getGroupingKeywordsPrompt">
                                    <div class="col-12">
                                        <span>
                                            <span>Grouping Keywords Prompt</span> <i class="bi bi-pencil-square"
                                                @click="viewGroupingKeywordsPrompt()"></i>
                                            <div class="form-group" v-if="isGroupingKeywordsPrompt === true">
                                                <textarea @input="updateGroupingKeywordsPromptInput()" class="form-control"
                                                    id="exampleFormControlTextarea1" rows="3"
                                                    v-if="isGroupingKeywordsPrompt == true"
                                                    v-model="promptGroupingKeywords"></textarea>
                                                <button class="btn btn-sm btn-success mt-2"
                                                    :disabled="requestSteps.disabledPrompt == true"
                                                    @click="promptUpdateGroupingKeywords()">
                                                    Update Grouping Prompt
                                                </button>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
// import ProgressSpinner from 'primevue/progressspinner';
export default {
    name: "RightSidebarComponent",
    props: ['rightSidebarToggle', 'answer', 'requestSteps', 'isLoading'],
    components: {
        // ProgressSpinner
    },
    computed: {
        ...mapGetters({
            getCompetitorsPrompt: "getCompetitorsPrompt",
            getKeywordsPrompt: "getKeywordsPrompt",
            getGroupingKeywordsPrompt: "getGroupingKeywordsPrompt",
        }),

    },
    data() {
        return {
            title: 'Sidebar',
            isCompetitorPrompt: false,
            isKeywordsPrompt: false,
            isGroupingKeywordsPrompt: false,
            promptCompetitors: '',
            promptKeywords: '',
            promptGroupingKeywords: '',
            loadingSpin: ''

        }
    },
    methods: {
        updateCompetitorPromptInput() {
            this.$store.dispatch('setCompetitorsPrompt', this.promptCompetitors);
        },
        updateKeywordsPromptInput() {
            this.$store.dispatch('setKeywordsPrompt', this.promptKeywords);
        },
        updateGroupingKeywordsPromptInput() {
            this.$store.dispatch('setGroupingKeywordsPrompt', this.promptGroupingKeywords);
        },
        handleRightSidebar() {
            this.$emit('changeRightSidebar');
        },
        viewCompetitorPrompt() {
            this.isCompetitorPrompt = !this.isCompetitorPrompt;
        },
        viewKeywordsPrompt() {
            this.isKeywordsPrompt = !this.isKeywordsPrompt;
        },
        viewGroupingKeywordsPrompt() {
            this.isGroupingKeywordsPrompt = !this.isGroupingKeywordsPrompt;
        },
        updateCompetitorPrompt() {
            this.$store.dispatch('setCompetitorsPrompt', this.promptCompetitors);
            this.$emit('updateCompetitorPrompt', this.getCompetitorsPrompt);
            this.promptCompetitors = this.getCompetitorsPrompt;
        },
        promptUpdateKeywords() {
            this.$store.dispatch('setKeywordsPrompt', this.promptKeywords);
            this.$emit('updateKeywordsPrompt', this.getKeywordsPrompt);
            this.promptKeywords = this.getKeywordsPrompt;
        },

        promptUpdateGroupingKeywords() {
            this.$store.dispatch('setGroupingKeywordsPrompt', this.promptGroupingKeywords);
            this.$emit('updateGroupingKeywordsPrompt', this.getGroupingKeywordsPrompt);
            this.promptGroupingKeywords = this.getGroupingKeywordsPrompt;
        }

    },
    mounted() {
        this.promptCompetitors = this.getCompetitorsPrompt;
        console.log(this.getCompetitorsPrompt, "Updated Competitor Prompt");
        this.promptKeywords = this.getKeywordsPrompt;
        this.promptGroupingKeywords = this.getGroupingKeywordsPrompt;
    }
}
</script>
<style>
.sidebar-toggle .sidebar {
    display: none;
}

.right-navbar {
    position: fixed;
    width: 20%;
    z-index: 400;
}
</style>