<template>
    <div class="mt-5">
        <div v-html="choseTheRelevantPage"></div>
    </div>
</template>
<script>
export default {
    props: ['choseTheRelevantPage'],
    data() {
        return {

        }
    },

    mounted() {
    }
}
</script>