import { createApp } from "vue";
import App from "./App.vue";
import store from "./vuex.js";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import ToastPlugin from "vue-toast-notification";
import "vue-toast-notification/dist/theme-bootstrap.css";

import PrimeVue from "primevue/config";
import "primevue/resources/themes/lara-light-green/theme.css";

const app = createApp(App);

app.use(store);
app.use(VueSweetalert2);
app.use(ToastPlugin);
app.use(PrimeVue);
app.mount("#app");
