<template>
    <div class="col-10">
        <button v-if="requestSteps.showRegenerateKeywords == true" title="Regenerate"
            class="btn btn-default bg-transparent shadow-none text-light d-flex align-items-center justify-content-end"
            @click="promptRegenerateHandler()">Regenerate
        </button>
        <div id="promptInputBox"
            class="row card card bg-dark rounded-main-content-card d-flex flex-row align-items-center justify-content-between">
            <div class="col-11">
                <textarea @input="handleSearchChange()" cols="90" rows="2" max-rows style=" max-height: 200px;border: none !important;outline: none !important;
                    height: 56px;resize:none;
                    overflow-y: hidden;" class="bg-dark main-content-search" placeholder=" Write something..."
                    v-model="searchValue">
                  </textarea>
            </div>
            <div class="col-1 px-3">
                <button title="send message" class="btn btn-default bg-transparent" v-if="promptBtnDisabled == true"
                    :disabled="promptBtnDisabled">
                    <img src="../assets/search-icon-fill.svg" alt="">
                </button>
                <button title="send message" class="btn btn-default bg-transparent shadow-none"
                    @click="promptClickHandler()" v-else :disabled="promptBtnDisabled">
                    <img src="../assets/search-icon.svg" alt="">
                </button>

            </div>
        </div>
    </div>
</template>

<script>
import AIHelper from "../mixins/AIHelper";

export default {
    name: "SearchComponent",
    mixins: [AIHelper],
    props: ['requestSteps'],
    components: {},
    data() {
        return {
            title: 'Search',
            searchValue: '',
            promptBtnDisabled: true
        }
    },
    methods: {
        promptClickHandler() {
            this.$emit('promptClick', this.searchValue);
        },
        handleSearchChange() {
            if (this.searchValue.length > 1) {
                this.promptBtnDisabled = false;
            }
            else {
                this.promptBtnDisabled = true;
            }
        },

        promptRegenerateHandler() {
            this.$emit('regenerate');
        }

    }
}
</script>
<style>
.sidebar-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 20%;
    background: #161B22;
    color: #fff;
    height: 100%;
    transition: all 0.3s ease;
    border-right: 1px solid #21262D !important;
}

.right-sidebar-container {
    position: absolute;
    top: 0;
    right: 0;
    width: 20%;
    background: #161B22;
    color: #fff;
    height: auto;
    transition: all 0.3s ease;

}

.img {
    cursor: pointer;
}

.sidebar-toggle {
    width: 0%;
    transition: all 0.3s ease;
    /* height: 0; */
}

.right-sidebar-toggle {
    width: 0%;
    transition: all 0.3s ease;
    /* height: 0; */
}

.right-side-content {
    position: fixed;
    top: 0;
    transition: all 0.3s ease;
    background: #161B22;
    height: 100%;
    overflow-y: scroll;
    border-right: 1px solid #21262D !important;
}

.left-20 {
    left: 20% !important;
}

.visible-scrollbar,
.invisible-scrollbar,
.mostly-customized-scrollbar {
    display: none;
    width: 10em;
    overflow: auto;
    height: 2em;
}

.right-side-content::-webkit-scrollbar {
    display: none;
}

.navbar-toggle {
    width: 100%;
    left: 0;
}

p {
    cursor: pointer;
}

/* #promptInputBox {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 60%;
} */

.minus-left-15 {
    position: relative;
    right: 15% !important;
}
</style>   