<template>
    <div id="sidebar">
        <div :class="sidebarToggle == false ? 'left-navbar' : ''"
            style="background-color: #161B22; border-bottom: 1px solid #21262D !important" class="row py-3 d-flex bg-dark">
            <div v-if="sidebarToggle == false" class="col-12 px-3 d-flex align-items-center justify-content-between pe-3">
                <a class="navbar-brand" href="#">
                    <img src="../assets/nav-logo.png" alt="">
                </a>
                <a @click="handleSidebarToggle()" id="sidebar-toggler-btn">
                    <img src="../assets/expand.svg" alt="">
                </a>
            </div>
        </div>
        <div v-if="sidebarToggle == false" class="px-3">
            <div class="row">
                <div class="col-12 pt-5 mt-5">
                    <p class="sidebar-title-recently">Recently Activity</p>
                    <hr />
                </div>
                <div class="col-12">
                    <div class="card rounded-side-bar" style="background-color: #21262D !important;">
                        <div class="row">
                            <div class="col-12">
                                <div v-for="(data, index) in historyData" :key="index" class="row px-3 pt-3">
                                    <div class="col-8">
                                        <p class="sidebar-name-of-company">
                                            {{ data.name }}
                                        </p>
                                    </div>
                                    <div class="col-4 d-flex justify-content-end">
                                        <p class="sidebar-date-time">
                                            {{ data.date }}
                                        </p>
                                    </div>
                                    <hr />
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="row px-3 pt-3">
                                <div class="col-12">
                                    <p class="sidebar-see-all-recent-chats">See all recent chats</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "SidebarComponent",
    props: ['sidebarToggle', 'answer'],
    components: {
        // Navbar,
        // Sidebar,
    },
    data() {
        return {
            title: 'Sidebar',
            historyData: [
                { id: 1, name: 'Name of Company 1', date: '9:54 pm' },
                { id: 2, name: 'Name of Company 2', date: '9:54 pm' },
                { id: 3, name: 'Name of Company 3', date: '9:54 pm' },
                { id: 4, name: 'Name of Company 4', date: '9:54 pm' },
                { id: 5, name: 'Name of Company 5', date: '9:54 pm' },
            ]

        }
    },
    methods: {
        handleSidebarToggle() {
            this.$emit('changeLeftSidebarToggle');
        }
    }
}
</script>
<style>
.sidebar-toggle .sidebar {
    width: 0;
    transition: width 0.5s;
}

.left-navbar {
    position: fixed;
    width: 20%;
    z-index: 400;
}
</style>