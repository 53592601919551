<template>
    <div v-if="apiCompetitors == true">
        <div class="card rounded-main-content-card" style=" border-right: 6px solid #3287C2 !important;">
            <p class="main-content-question" style="border-radius: 0.5rem 0.375rem 0.375rem 0.5rem;
                border: 1px solid #21262D;
                background: #161B22;">
                {{ competitorsDescription }}
            </p>
        </div>
        <div class="card rounded-main-content-card mt-3">
            <div class="d-flex justify-content-start py-2" v-for="(competitor, index) in competitors" :key="index">
                <div class="text-white px-3 d-flex align-items-center justify-content-center">
                    <i class="bi bi-trash text-danger" @click="deleteCompetitor(index)" style="cursor: pointer;"></i>
                </div>
                <div class="text-white" @mouseover="changeImageHover('sad-face-competitor', index)"
                    @mouseout="changeEmoji(index)">
                    <img v-if="competitor.sad == true || (imageSrc == true && imageHover == index)"
                        src="../assets/img/sad-fill.png" @click="filterCompetitorHandler(index)">
                    <img v-else src="../assets/img/sad.png">
                </div>
                <div :class="competitor.sad == true || (imageSrc == true && imageHover == index) ? 'text-deleted' : ''"
                    class="text-white ps-2">
                    {{ competitor.competitor }}
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 d-flex justify-content-end align-bottom rounded-main-content-card p-3">
                    <div style="position: absolute; left:63%;transform: translate(-50%,-50%);z-index: 500;"
                        v-if="apiCompetitorsLoading">
                        <img src="../assets/img/loader.gif" alt="">
                        <LvProgressBar mode="indeterminate" color="#bb2727" />
                    </div>
                    <button type="button" class="btn btn-sm prune-and-regenerate-btn"
                        :disabled="disableInputs.nextCompetitorsButtonDisable == true"
                        style=" color: azure; margin-right: 12px !important; background-color: rgb(50, 135, 194) !important; border: 1px solid rgb(50, 135, 194); border-radius: 8px !important ;"
                        @click="finalizeCompetitors()">
                        Next
                    </button>
                    <button type="button" class="btn btn-sm prune-and-regenerate-btn"
                        :disabled="disableInputs.nextCompetitorsButtonDisable == true"
                        style="background-color: #21262D !important; border: 1px solid rgb(134, 131, 131); border-radius: 8px !important ;"
                        @click="pruneRegenerateCompetitors()">
                        + Prune and regenerate
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import LvProgressBar from 'lightvue/progress-bar';

export default {
    props: ['apiCompetitorsLoading', 'competitors', 'question', 'apiCompetitors', 'disableInputs'],
    components: {
        LvProgressBar
    },
    data() {
        return {
            competitorsDescription: 'thanks for providing me with keywords to avoid... next step....are these your possible list of competitors? if i provided a wrong competitor, mark it with a sad face',
            imageSrc: false,
            imageHover: null,
            avoidCompetitors: this.question.avoidCompetitors ? this.question.avoidCompetitors : 0,
        }
    },

    methods: {
        changeImageHover(type, index) {
            if (type == 'sad-face-competitor') {
                this.imageHover = index;
                this.imageSrc = true;
            }
            if (type == 'sad') {
                this.imageHover = index;
                this.imageSrc = true;
            }
        },

        changeEmoji() {
            this.imageSrc = false;
            this.imageHover = 0;
        },
        filterCompetitorHandler(index) {
            this.$emit('filterCompetitorHandler', index);
        },

        finalizeCompetitors() {
            this.$emit('finalizeCompetitors');
        },

        deleteCompetitor(index) {
            this.$emit('deleteCompetitor', index);
        },

        pruneRegenerateCompetitors() {
            if (this.question.avoidCompetitors.length == 0) {
                return this.$swal({
                    title: 'Error!',
                    text: 'Please provide at least one competitor to avoid',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
            this.$emit('avoidCompetitorRegenerate', this.question.avoidCompetitors);
        },
    }
}

</script>