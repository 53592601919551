<template>
    <div>
        <div v-if="aiKeywords == true" class=" card rounded-main-content-card mt-3"
            style=" border-right: 6px solid #3287C2 !important;">
            <p class="main-content-question" style="border-radius: 0.5rem 0.375rem 0.375rem 0.5rem;
                border: 1px solid #21262D;
                background: #0D1117;">
                {{ description }}
            </p>
        </div>
        <div class="card mt-3" style=" border-radius: 0.5rem;
                border: 1px solid #21262D;
                background: #161B22;">
            <div class="card-body" v-if="aiKeywords == true">
                <div class="row">
                    <div class="col-12">
                        <div>
                            <div>
                                <div class="d-flex align-items-center justify-content-start"
                                    v-for="(keywordObject, index) in generatedKeywords" :key="index">
                                    <div class="text-white p-2">
                                        <i class="bi bi-trash text-danger" @click="deleteKeyword(index)"
                                            style="cursor: pointer;"></i>
                                    </div>
                                    <div class="text-white" @mouseover="changeImageHover('sad', index)"
                                        @mouseout="changeEmoji(index)">
                                        <img v-if="keywordObject.sad == true || (imageSrc == true && imageHover == index)"
                                            src="../assets/img/sad-fill.png" @click="filterHandler(index)">
                                        <img v-else src="../assets/img/sad.png">
                                    </div>
                                    <div style="position: absolute;top: 50%; left:50%;transform: translate(-50%,-50%);z-index: 500;"
                                        v-if="isLoadingKeyword">
                                        <img src="../assets/img/loader.gif" alt="">
                                        <LvProgressBar mode="indeterminate" color="#bb2727" />
                                    </div>

                                    <div :class="keywordObject.sad == true || (imageSrc == true && imageHover == index) ? 'text-deleted' : ''"
                                        class="text-white ps-2">
                                        {{ keywordObject.keyword }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div
                                        class="col-md-12 d-flex justify-content-end align-bottom rounded-main-content-card p-3">
                                        <div style="position: absolute; left:60%;transform: translate(-50%,-50%);z-index: 500;"
                                            v-if="isLoadingKeyword">
                                            <img src="../assets/img/loader.gif" alt="">
                                            <LvProgressBar mode="indeterminate" color="#bb2727" />
                                        </div>
                                        <button type="button" class="btn btn-sm prune-and-regenerate-btn"
                                            :disabled="disableInputs.nextKeywordButtonDisable == true"
                                            style=" color: azure; margin-right: 12px !important; background-color: rgb(50, 135, 194) !important; border: 1px solid rgb(50, 135, 194); border-radius: 8px !important ;"
                                            @click="finalizeKeywordsGetGroupingKeywords()">
                                            Next
                                        </button>
                                        <button type="button" class="btn btn-sm prune-and-regenerate-btn"
                                            :disabled="disableInputs.nextKeywordButtonDisable == true"
                                            style="background-color: #21262D !important; border: 1px solid rgb(134, 131, 131); border-radius: 8px !important ;"
                                            @click="pruneRegenerateKeywords()">
                                            + Prune and regenerate
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="groupingKeywordsSections == true" class="card rounded-main-content-card mt-3">
                <div v-html="getGroupingKeywordsView"></div>
                <div class="row">
                    <div class="col-12 my-3">
                        <div class="card bg-dark rounded-main-content-card">
                            <p class="main-content-question" style="border-right: 6px solid #3287C2; border-radius: 8px;">
                                Please enter your domain name
                            </p>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="input-group">
                            <input type="text" class="form-control" :disabled="disableInputs.webDomain == true"
                                v-model="userWebsite" @keypress="handlerUserDomainProm"
                                placeholder="Enter Your Domain Name">
                        </div>
                        <div style=" position: absolute; top: 89%;left: 36%;transform: translate(-50%, -50%);z-index: 500;"
                            v-if="setUrlGroupingKeywordsLoading">
                            <img src="../assets/img/loader.gif" alt="">
                            <LvProgressBar mode="indeterminate" color="#bb2727" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import LvProgressBar from 'lightvue/progress-bar';
export default {
    name: "GeneratedKeywordComponent",
    components: {
        LvProgressBar
    },
    props: ['requestSteps', 'groupingKeywordsSections', 'setUrlGroupingKeywordsLoading', 'getUserDomain', 'disableInputs', 'description', 'generatedKeywords', 'apiDataLoading', 'question', 'aiKeywords', 'isLoadingKeyword', 'groupingKeywords'],
    data() {
        return {
            imageSrc: false,
            imageHover: 0,
            title: 'Generated Keywords',
            keywordDemand: this.question.demandKeywords ? this.question.demandKeywords : 0,
            competitorMode: false,
            avoidKeywords: this.question.avoidKeywords ? this.question.avoidKeywords : 0,
            userWebsite: this.question.webDomain ? this.question.webDomain : '',
            // getGroupingKeywords: this.groupingKeywords ? this.groupingKeywords : [],
        }
    },
    computed: {

        getGroupingKeywordsView() {
            var viewTemplate = '';
            if (this.groupingKeywords.length > 0) {
                this.groupingKeywords.forEach((group, index) => {
                    const group_name = Object.keys(group);
                    let count = index + 1;
                    viewTemplate += "<p class='ps-4 text-light mb-1'>" + count + "." + group_name + ":</p><ul class='list-unstyled'>";
                    group[group_name].forEach((keyword) => {
                        viewTemplate += "<li class='ps-5 text-light'>-" + keyword + "</li>";
                    });
                    viewTemplate += "</ul><br/>";
                });
            }
            return viewTemplate;
        }

        // getGroupingKeywordsView() {
        //     var viewTemplate = '';
        //     this.groupingKeywords.forEach((group, index) => {
        //         const group_name = group.group_name;
        //         const keywords = group.keywords;

        //         let count = index + 1;

        //         viewTemplate += "<p class='ps-4 text-light mb-1'>" + count + "." + group_name + ":</p><ul class='list-unstyled'>";

        //         if (Array.isArray(keywords)) {
        //             keywords.forEach(keywordObj => {
        //                 const keyword = keywordObj.keyword;
        //                 if (typeof keyword === 'string') {
        //                     viewTemplate += "<li class='ps-5 text-light'>-" + keyword + "</li>";
        //                 }
        //             });
        //         }

        //         viewTemplate += "</ul><br/>";
        //     });

        //     return viewTemplate;
        // }
    },
    methods: {
        changeFieldStatus() {
            this.$emit('changeFieldStatus');
        },

        pruneRegenerateKeywords() {
            if (this.question.avoidKeywords.length == 0) {
                return this.$swal({
                    title: 'Error!',
                    text: 'Please provide at least one keyword to avoid',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
            this.$emit('proneRegenerateKeywords', this.question.avoidKeywords)
        },


        pressEnterSecondTimeHandler(e) {
            if (e.key === 'Enter') {
                this.$emit('pressEnterSecondTimeHandler', this.keywordDemand);
            }
        },

        getCompetitor() {
            this.competitorMode = !this.competitorMode;
            this.$emit('getCompetitor', this.competitorMode);
        },

        changeImageHover(type, index) {
            if (type == 'sad-face-competitor') {
                this.imageHover = index;
                this.imageSrc = true;
            }
            if (type == 'sad') {
                this.imageHover = index;
                this.imageSrc = true;
            }
        },

        changeEmoji() {
            this.imageSrc = false;
            this.imageHover = 0;
        },

        filterHandler(index) {
            this.$emit('filterHandler', index);
        },

        deleteKeyword(index) {
            this.$emit('deleteKeyword', index);
        },

        finalizeKeywordsGetGroupingKeywords() {
            this.$emit('finalizeKeywordsGetGroupingKeywords')
        },

        handlerUserDomainProm(e) {
            if (e.key === 'Enter') {
                this.$emit('handlerUserDomainProm', this.userWebsite)
            }
        }
    },
}


</script>