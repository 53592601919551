<template>
    <div>
        <div class="container-fluid bg-dark">
            <div class="row">
                <div :class="sidebarToggle ? 'sidebar-toggle' : ''" class="sidebar-container">
                    <Sidebar :sidebarToggle="sidebarToggle" :companies="companies"
                        @changeLeftSidebarToggle="sidebarToggleHandler()" />
                </div>
                <div :class="checkingSideLayouts()" class="px-0 right-side-content">
                    <Navbar :rightSidebarToggle="rightSidebarToggle" :sidebarToggle="sidebarToggle"
                        @changeSidebarToggle="sidebarToggleHandler()"
                        @changeRightSidebarToggle="rightSidebarToggleHandler()" @addNewChange="addNewChange()" />
                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                <div>
                                    <div class="row">
                                        <div class="col-10 m-auto" style="padding-top: 10.625rem">
                                            <div class="row">
                                                <div v-if="requestSteps.companyName == true">
                                                    <div class="col-10">
                                                        <!-- <LvProgressBar :value="value" :showValue="false" color="#38b2ac" /> -->
                                                        <div class="card bg-dark rounded-main-content-card">
                                                            <p class="main-content-question"
                                                                style=" border-right: 6px solid #3287C2;border-radius: 8px;">
                                                                What is your company name?
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="col-10">
                                                        <div class="card my-3 rounded "
                                                            style="background-color: #3287C2!important">
                                                            <div class="form-group">
                                                                <input :disabled="disableInputs.companyName == true"
                                                                    type="text" class="form-control company-name"
                                                                    v-model="question.companyName"
                                                                    placeholder="Enter Your Company Name"
                                                                    @keypress="pressEnterHandler">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="keywords == true">
                                                    <div style="position: absolute;top: 50%; left:43%;transform: translate(-50%,-50%);z-index: 500;"
                                                        v-if="isLoading">
                                                        <img src="../assets/img/loader.gif" alt="">
                                                        <LvProgressBar mode="indeterminate" color="#bb2727" />
                                                    </div>
                                                    <div class="col-10">
                                                        <div class="card bg-dark rounded-main-content-card">
                                                            <p class="main-content-question"
                                                                style="border-right: 6px solid #3287C2; border-radius: 8px;">
                                                                Perfect. Please provide me with a list of keywords. 10
                                                                Keywords should be enough.
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="col-10">
                                                        <div class="card my-3 rounded "
                                                            style="background-color: #3287C2!important">
                                                            <div class="form-group">
                                                                <textarea :disabled="disableInputs.keywords == true"
                                                                    cols="90" rows="2" max-rows style=" max-height: 200px;border: none !important;outline: none !important;
                                                                    height: 56px;resize:none;
                                                                    overflow-y: hidden;" class="form-control"
                                                                    placeholder=" keyword 1,2,3,4,5,6,7,8,9,10"
                                                                    v-model="question.keywords"
                                                                    @keypress="pressEnterSecondTimeHandler">
                                                                </textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-10">
                                                    <!-- competitors component start -->
                                                    <CompetitorsComponent :question="question"
                                                        :competitors="generatedCompetitors"
                                                        :apiCompetitorsLoading="apiCompetitorsLoading"
                                                        :apiCompetitors="apiCompetitors" :disableInputs="disableInputs"
                                                        @avoidCompetitorRegenerate="avoidCompetitorRegenerate"
                                                        @filterCompetitorHandler="filterCompetitorHandler"
                                                        @finalizeCompetitors="finalizeCompetitors"
                                                        @deleteCompetitor="deleteCompetitor" />
                                                    <!-- competitors component start -->


                                                    <!-- keywords component start -->
                                                    <GeneratedKeywordComponent :apiDataLoading="apiDataLoading"
                                                        :aiKeywords="aiKeywords" :description="description"
                                                        :generatedKeywords="generatedKeywords"
                                                        :isLoadingKeyword="isLoadingKeyword" :is-loading="isLoading"
                                                        :question="question" :groupingKeywords="backendGroupingKeywords"
                                                        :requestSteps="requestSteps" :disableInputs="disableInputs"
                                                        :setUrlGroupingKeywordsLoading="setUrlGroupingKeywordsLoading"
                                                        :groupingKeywordsSections="groupingKeywordsSections"
                                                        @changeFieldStatus="changeFieldStatus"
                                                        @filterHandler="filterHandler"
                                                        @pressEnterSecondTimeHandler="moreKeywords"
                                                        @deleteKeyword="deleteKeyword"
                                                        @proneRegenerateKeywords="proneRegenerateKeywords"
                                                        @finalizeKeywordsGetGroupingKeywords="finalizeKeywordsGetGroupingKeywords"
                                                        @handlerUserDomainProm="handlerUserDomainProm" />
                                                    <!-- keywords component start -->
                                                </div>

                                                <div class="col-md-10">
                                                    <div style="position: absolute;top: 50%; left:43%;transform: translate(-50%,-50%);z-index: 500;"
                                                        v-if="setUrlGroupingKeywordsLoading">
                                                        <img src="../assets/img/loader.gif" alt="">
                                                        <LvProgressBar mode="indeterminate" color="#bb2727" />
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-10 text-light"
                                                            v-if="this.finalUrlGroupingKeywords == true">
                                                            <SetFinalUrlsGroupingComponent
                                                                :choseTheRelevantPage="extractedFinalUrlLinksHTML" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="height: 135px;">
                        </div>

                    </div>
                </div>
                <div :class="rightSidebarToggle ? 'right-sidebar-toggle' : ''" class="right-sidebar-container">
                    <RightSidebar :rightSidebarToggle="rightSidebarToggle" @changeRightSidebar="rightSidebarToggleHandler()"
                        :companies="companies" :requestSteps="requestSteps" @updateCompetitorPrompt="updateCompetitorPrompt"
                        @updateKeywordsPrompt="updateKeywordsPrompt"
                        @updateGroupingKeywordsPrompt="updateGroupingKeywordsPrompt" />
                </div>
                <div :class="sidebarToggle == false && rightSidebarToggle == true ? 'left-11' : ''"
                    class="prompt-search-wrapper">
                    <div class="row">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-10 m-auto">
                                    <div class="mt-3 prompt-search-container">
                                        <SearchComponent :requestSteps="requestSteps" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import AIHelper from "../mixins/AIHelper";
import Navbar from "../components/Navbar.vue";
import Sidebar from "../components/Sidebar.vue";
import RightSidebar from "../components/RightSidebar.vue";
import { mapGetters } from "vuex";
import axios from "axios";
import SearchComponent from "../components/SearchComponent.vue";
import GeneratedKeywordComponent from "@/components/GeneratedKeywordComponent.vue";
import SetFinalUrlsGroupingComponent from "@/components/SetFinalUrlsGroupingComponent";
import CompetitorsComponent from "@/components/CompetitorsComponent.vue";
import LvProgressBar from 'lightvue/progress-bar';


export default {
    name: "BaseLayout",
    mixins: [AIHelper],
    components: {
        Navbar,
        Sidebar,
        RightSidebar,
        SearchComponent,
        GeneratedKeywordComponent,
        CompetitorsComponent,
        SetFinalUrlsGroupingComponent,
        LvProgressBar
    },

    data() {
        return {
            user_id: 1,
            company_id: null,
            apiUrl: process.env.BASE_URL,

            apiCompetitors: false,
            apiCompetitorsLoading: false,


            setUrlGroupingKeywordsLoading: false,
            finalUrlKeywords: null,

            choseTheRelevantPage: null,
            extractedFinalUrlLinksHTML: "",

            backendGroupingKeywords: "",

            aiCampaignName: null,


            disableInputs: {
                companyName: false,
                keywords: false,
                competitors: false,
                avoidKeywords: false,
                userSuggestedKeywords: false,
                keywordDemand: false,
                groupingKeywords: false,
                avoidCompetitors: false,
                webDomain: false,
                nextCompetitorsButtonDisable: false,
                nextKeywordButtonDisable: false
            },
            value: 0,
            imageSrc: false,
            imageHover: 0,
            keywords: false,
            enableContainerLeft: false,
            sidebarToggle: false,
            rightSidebarToggle: true,
            isLoading: false,
            isLoadingKeyword: false,
            promptFocus: false,
            promptBtnDisabled: true,

            apiDataLoading: false,
            generatedKeywords: null,
            generatedCompetitors: null,
            userDomainData: null,
            generatedGroupingKeywords: null,
            aiKeywords: false,
            groupingKeywordsSections: false,
            searchValue: '',
            finalUrlGroupingKeywords: false,
            requestSteps: {
                companyName: true,
                keywords: false,
                avoidKeywords: false,
                showUserSuggestedKeywords: false,
                disableSuggestedKeywordInput: false,
                addMore: false,
                competitors: false,
                avoidCompetitors: false,
                demandKeywords: false,
                groupingKeywords: false,
                competitorPrompt: false,
                keywordsPrompt: false,
                groupingKeywordsPrompt: false,
                disabledPrompt: false,
                userDomain: false,
                scrapingUrl: false,
                campaignName: false,
            },
            description: "Thanks, after researching similar keywords with the same theme and intent and consider your list of competitors, I got the following keyword list, which one is good and which one is bad?",
            question: {
                companyName: "",
                keywords: "",
                avoidKeywords: "",
                competitors: "",
                userSuggestedKeywords: "",
                keywordDemand: 0,
                groupingKeywords: "",
                avoidCompetitors: "",
                webDomain: "",
                scrapingUrl: "",
                campaignName: "",
            },
            requestMode: 'generate_keywords',
            companies: [],
        }
    },
    interval: null,

    methods: {
        startProgress() {
            this.interval = setInterval(() => {
                let newValue = this.value + Math.floor(Math.random() * 10) + 1;
                if (newValue >= 100) {
                    newValue = 0;
                }
                this.value = newValue;
            }, 2000);
        },

        endProgress() {
            clearInterval(this.interval);
            this.interval = null;
        },

        beforeDestroy() {
            this.endProgress();
        },

        filterCompetitorHandler(filter) {
            this.generatedCompetitors[filter].sad = true;
            this.requestSteps.competitors = true;
            let filterKeywordArray = this.generatedCompetitors[filter].keyword.split('.');
            let filterKeyword = filterKeywordArray[0];
            this.question.avoidCompetitors.includes(filterKeyword) ? this.$toast.warning('this competitor already exist', {
                position: "top-right",
                autoClose: 5000,
            }) : this.question.avoidCompetitors += filterKeyword + ',';

        },

        filterHandler(filter) {
            this.generatedKeywords[filter].sad = true;
            this.requestSteps.keywords = true;
            this.requestSteps.showUserSuggestedKeywords = true;
            this.requestSteps.avoidKeywords = true;

            let filterKeywordArray = this.generatedKeywords[filter].keyword.split('.');
            let filterKeyword = filterKeywordArray[0];
            this.question.avoidKeywords.includes(filterKeyword) ? this.$toast.error('this keyword already exist', {
                position: "top-right",
                autoClose: 5000,
            }) : this.question.avoidKeywords += filterKeyword + ',';

        },

        changeFieldStatus() {
            if (this.requestSteps.disableSuggestedKeywordInput == true && this.requestSteps.addMore == false) {
                this.requestSteps.addMore = true;
            }
            else {
                this.requestSteps.keywords = true;
            }

            return this.requestSteps
        },

        sidebarToggleHandler() {
            this.sidebarToggle = !this.sidebarToggle;
        },

        rightSidebarToggleHandler() {
            this.rightSidebarToggle = !this.rightSidebarToggle;
        },

        avoidKeywordsHandler(e) {
            if (e.keyCode === 13) {
                this.requestSteps.showUserSuggestedKeywords = true;
            }
        },

        async pressEnterSecondTimeHandler(e) {
            const startTime = performance.now();
            if (e.keyCode === 13) {
                try {
                    this.isLoading = true;
                    const data = {
                        companyName: this.question.companyName,
                        keywords: this.question.keywords,
                        competitors: this.question.competitors,
                        avoidKeywords: this.question.avoidKeywords,
                        userSuggestedKeywords: this.question.userSuggestedKeywords,
                        keywordDemand: this.question.keywordDemand,
                    }
                    this.companies.push(data);
                    this.$store.dispatch("setCompany", this.companies);
                    this.requestSteps.keywords = false;
                    this.requestSteps.competitors = true;
                    this.isLoadingKeyword = true;
                    this.$store.dispatch("setCompetitorsPrompt", `generate 10 competitors for ${this.question.companyName} based on the following keywords: ${this.question.keywords} and return a list of 10 competitors.`)
                    var ai_response = await this.processAIResponse(this.question, this.requestSteps);
                    this.$store.dispatch("setCompetitors", ai_response.AIData);

                    if (ai_response.currentStep == 'competitors') {
                        this.storeCompany();
                        let competitorArray = this.convertCompetitorStringToArray(ai_response.AIData);
                        this.apiCompetitors = true;

                        const endTime = performance.now();
                        const responseTime = endTime - startTime;
                        this.value = responseTime;
                        this.generatedCompetitors = competitorArray;
                        this.disableInputs.keywords = true;
                    }
                    else {
                        this.$store.dispatch("setAiData", ai_response.AIData);
                        this.generatedKeywords = this.convertKeywordStringToArray(ai_response.AIData);
                        this.changeFieldStatus();
                        this.endProgress();
                    }
                    this.isLoading = false;
                    if (ai_response.currentStep == 'avoidKeywords') {
                        this.requestSteps.disableSuggestedKeywordInput = true;
                    }
                    this.isLoadingKeyword = false;
                }
                catch (error) {
                    this.isLoading = false;
                    this.$toast.warning(error, {
                        position: "top-right",
                        autoClose: 5000,
                    });
                }
            }
        },

        async avoidCompetitorRegenerate(question) {

            this.apiCompetitorsLoading = true;
            this.startProgress();
            this.requestSteps.competitors = false;
            this.requestSteps.avoidCompetitors = true;
            var ai_response = await this.processAIResponse(question, this.requestSteps);

            if (ai_response.currentStep == 'avoidCompetitors') {
                let competitorArray = this.convertCompetitorStringToArray(ai_response.AIData);
                this.$store.dispatch("setCompetitors", ai_response.AIData);

                this.generatedCompetitors = competitorArray;
                this.apiCompetitorsLoading = false;
                this.requestSteps.avoidCompetitors = false;
                this.question.avoidCompetitors = '';
            }
        },

        async proneRegenerateKeywords(question) {
            this.isLoadingKeyword = true;
            this.startProgress();
            this.requestSteps.keywords = false;
            this.requestSteps.avoidKeywords = true;
            var ai_response = await this.processAIResponse(question, this.requestSteps);
            this.isLoadingKeyword = false;

            if (ai_response.currentStep == 'avoidKeywords') {
                let keywordArray = this.convertKeywordStringToArray(ai_response.AIData);
                this.$store.dispatch("setAiData", ai_response.AIData);
                this.generatedKeywords = keywordArray;
                this.isLoadingKeyword = false;
                this.requestSteps.avoidKeywords = false;
                this.question.avoidKeywords = '';
            }
        },

        async finalizeCompetitors() {
            this.apiCompetitorsLoading = true;
            this.requestSteps.avoidCompetitors = false;
            this.requestSteps.keywords = true;
            var ai_response = await this.processAIResponse(this.question, this.requestSteps);

            if (ai_response.currentStep == 'keywords') {
                this.storeCompetitors();
                this.$store.dispatch("setAiData", ai_response.AIData);
                this.generatedKeywords = this.convertKeywordStringToArray(this.getAiData);
                this.changeFieldStatus();
                this.apiCompetitorsLoading = false;
                this.requestSteps.keywords = false;
                this.aiKeywords = true;
                this.isLoading = false;
                this.disableInputs.nextCompetitorsButtonDisable = true;
            }
        },

        async moreKeywords(demandKeywords) {
            this.isLoadingKeyword = true;
            this.question.keywordDemand = demandKeywords;
            this.requestSteps.demandKeywords = true
            var ai_response = await this.processAIResponse(this.question, this.requestSteps);

            if (ai_response.currentStep == 'demandKeywords') {
                this.$store.dispatch("setAiData", ai_response.AIData);
                this.generatedKeywords = this.convertKeywordStringToArray(this.getAiData);
                this.changeFieldStatus();
                this.isLoadingKeyword = false;
            }

            this.$store.dispatch("setAiData", ai_response.AIData);
            this.generatedKeywords = this.convertKeywordStringToArray(this.getAiData);
            this.isLoading = false;
        },

        checkingSideLayouts() {
            if (this.sidebarToggle == false && this.rightSidebarToggle == false) {
                this.enableContainerLeft = false;
                return 'left-20 w-60';
            } else if (this.sidebarToggle == true && this.rightSidebarToggle == false) {
                this.enableContainerLeft = false;
                return 'navbar-toggle';
            } else if (this.sidebarToggle == false && this.rightSidebarToggle == true) {
                this.enableContainerLeft = true;
                return 'w-80 left-20';
            }
        },

        pressEnterHandler(e) {
            const startTime = performance.now();
            if (e.keyCode === 13) {
                this.isLoading = true;
                this.keywords = true;
                this.disableInputs.companyName = true;
                setTimeout(() => {
                    this.isLoading = false;
                    const endTime = performance.now();
                    const responseTime = endTime - startTime;
                    this.value = responseTime;
                    this.beforeDestroy();
                }, 500);
                this.changeFieldStatus();
            }
        },

        addNewChange() {
            this.isLoading = true;
            setTimeout(() => {
                this.isLoading = false;
            }, 500);
        },

        // convert string to array keywords
        convertKeywordStringToArray(keywordString) {
            let numberFound = 0;
            var generatedFinalData = null;
            if (keywordString == null || keywordString == "") return;
            generatedFinalData = keywordString.split('').map((t, index) => {
                if (Number(t) && index != 0) {
                    numberFound = numberFound + 1;
                    t = numberFound == 2 ? t : '<br />' + t;
                    return t;
                } else {
                    numberFound = 0;
                    return t;
                }
            }).join('');
            generatedFinalData = generatedFinalData.split('<br />');
            generatedFinalData = generatedFinalData.map((t, index) => {
                return { "index": index, keyword: t, smile: true, sad: false };
            });
            return generatedFinalData;
        },


        // convertArrayToStingCompetitor;
        convertCompetitorStringToArray(competitorString) {
            let numberFound = 0;
            var generatedFinalData = null;
            generatedFinalData = competitorString.split('').map((t, index) => {
                if (Number(t) && index != 0) {
                    numberFound = numberFound + 1;
                    t = numberFound == 2 ? t : '<br />' + t;
                    return t;
                } else {
                    numberFound = 0;
                    return t;
                }
            }).join('');
            generatedFinalData = generatedFinalData.split('<br />');
            generatedFinalData = generatedFinalData.map((t, index) => {
                return { "index": index, competitor: t, smile: true, sad: false };
            });
            return generatedFinalData;
        },

        deleteCompetitor(index) {
            this.generatedCompetitors.splice(index, 1);
            this.question.competitors = this.convertCompetitorStringToArray(this.generatedCompetitors);

            this.$store.dispatch("setCompetitors", this.question.competitors);
            this.$toast.success('competitor deleted', {
                position: "top-right",
                autoClose: 5000,
            });
        },

        deleteKeyword(index) {
            this.generatedKeywords.splice(index, 1);
            var newFinalizedKeywords = this.convertKeywordArrayToString(this.generatedKeywords);
            this.$store.dispatch("setAiData", newFinalizedKeywords);
            this.$toast.success('keyword deleted', {
                position: "top-right",
                autoClose: 5000,
            });
        },

        convertKeywordArrayToString(keywordArray) {
            let keywordString = '';
            keywordArray.forEach((element) => {
                keywordString += element.keyword + '.';
            });
            return keywordString;
        },

        // convertArrayToSting
        async finalizeKeywordsGetGroupingKeywords() {
            this.isLoadingKeyword = true;
            this.requestSteps.groupingKeywords = true;
            this.requestSteps.competitors = false;
            var ai_response = await this.processAIResponse(this.question, this.requestSteps);

            if (ai_response.currentStep == 'groupingKeywords') {

                if (ai_response.AIData.length == 0) {
                    this.$toast.error('the response is empty', {
                        position: "top-right",
                        autoClose: 5000,
                    });
                }

                if (ai_response.AIData.length > 0) {
                    var json_response = JSON.parse(ai_response.AIData);

                    this.backendGroupingKeywords = json_response.grouping_keywords;

                    this.$store.dispatch("setGroupingKeywords", this.backendGroupingKeywords);
                    console.log('this.backendGroupingKeywords', this.backendGroupingKeywords)
                    this.generatedGroupingKeywords = this.backendGroupingKeywords;

                    this.changeFieldStatus();
                    this.changeFieldStatus();
                    this.groupingKeywordsSections = true;
                    this.requestSteps.keywords = false;
                    this.aiKeywords = true;
                    this.isLoadingKeyword = false;
                    this.disableInputs.nextKeywordButtonDisable = true;
                    this.storeKeywords();
                }
            }
        },

        async updateCompetitorPrompt(competitorPrompt) {
            this.isLoading = true;
            const startTime = performance.now();
            this.requestSteps.disabledPrompt = true;
            this.requestSteps.competitors = true;
            this.requestSteps.competitorPrompt = true;
            this.requestSteps.avoidCompetitors = false;
            this.requestSteps.keywords = false;
            this.requestSteps.avoidKeywords = false;
            this.requestSteps.showUserSuggestedKeywords = false;
            this.question.competitors = competitorPrompt;
            var ai_response = await this.processAIResponse(this.question, this.requestSteps);
            this.$store.dispatch("setCompetitors", ai_response.AIData);

            if (ai_response.currentStep == 'competitors') {
                let competitorArray = this.convertKeywordStringToArray(ai_response.AIData);
                const endTime = performance.now();
                this.generatedCompetitors = competitorArray;
                this.requestSteps.disabledPrompt = false;

                const responseTime = endTime - startTime;
                this.value = responseTime;
                this.interval = null

            }
            else {
                this.$store.dispatch("setAiData", ai_response.AIData);
                this.generatedKeywords = this.convertKeywordStringToArray(ai_response.AIData);
                this.changeFieldStatus();
            }
            this.isLoading = false;
            this.isCompetitorPrompt = false;
            this.apiDataLoading = true;
            this.requestSteps.competitorPrompt = false;

        },

        async updateKeywordsPrompt(keywordsPrompt) {
            this.requestSteps.disabledPrompt = true;
            this.isLoading = true;
            this.isLoadingKeyword = true;
            this.requestSteps.keywords = true;
            this.requestSteps.keywordsPrompt = true;
            this.requestSteps.avoidCompetitors = false;
            this.requestSteps.avoidKeywords = false;
            this.requestSteps.showUserSuggestedKeywords = false;
            this.question.keywords = keywordsPrompt;

            var ai_response = await this.processAIResponse(this.question, this.requestSteps);
            if (ai_response.currentStep == 'keywords') {
                this.$store.dispatch("setAiData", ai_response.AIData);
                this.generatedKeywords = this.convertKeywordStringToArray(ai_response.AIData);
                this.changeFieldStatus();
                this.isLoading = false;
                this.isLoadingKeyword = false;
                this.isKeywordsPrompt = false;
                this.apiDataLoading = true;
                this.aiKeywords = true;
                this.requestSteps.disabledPrompt = false;
            }
        },

        async updateGroupingKeywordsPrompt(groupingKeywordsPrompt) {
            this.isLoadingKeyword = true;
            this.requestSteps.disabledPrompt = true;
            this.isLoading = true;
            this.requestSteps.groupingKeywords = true;
            this.requestSteps.groupingKeywordsPrompt = true;
            this.requestSteps.keywords = false;
            this.requestSteps.avoidCompetitors = false;
            this.requestSteps.avoidKeywords = false;
            this.requestSteps.showUserSuggestedKeywords = false;
            this.question.groupingKeywords = groupingKeywordsPrompt;
            var ai_response = await this.processAIResponse(this.question, this.requestSteps);
            if (ai_response.currentStep == 'groupingKeywords') {
                this.$store.dispatch("setGroupingKeywords", ai_response.AIData);
                this.isLoading = false;
                this.generatedGroupingKeywords = ai_response.AIData;
                this.changeFieldStatus();
                this.groupingKeywords = true;
                this.requestSteps.keywords = false;
                this.aiKeywords = true;
                this.isLoadingKeyword = false;
                this.isGroupingKeywordsPrompt = false;
                this.apiDataLoading = true;
                this.requestSteps.disabledPrompt = false;
            }
        },

        async handlerUserDomainProm(userWebsite) {
            try {
                if (userWebsite.length > 0) {
                    this.setUrlGroupingKeywordsLoading = true;
                    this.isLoading = true;
                    this.requestSteps.groupingKeywords = false;
                    this.requestSteps.groupingKeywordsPrompt = false;
                    this.requestSteps.keywords = false;
                    this.requestSteps.avoidCompetitors = false;
                    this.requestSteps.avoidKeywords = false;
                    this.requestSteps.showUserSuggestedKeywords = false;
                    this.requestSteps.userSuggestedKeywords = false;
                    this.requestSteps.userDomain = true;
                    this.question.webDomain = userWebsite;

                    const response = await axios.post(`${this.apiUrl}api/keywords/relevant_pages_view_set/`, {
                        'domain_name': this.question.webDomain,
                        'grouping_keywords': this.getGroupingKeywords,
                    });

                    if (response.data.relevant_pages_by_group.length == 0) {
                        this.$swal.fire(
                            'Error!',
                            "please domain name is incorrect try again",
                            'error',
                        )
                        this.finalUrlGroupingKeywords = false;
                    }

                    if (response.data.relevant_pages_by_group.length > 0) {

                        const urlString = response.data.relevant_pages_by_group.join("\n");

                        this.$store.dispatch("setFinalUrlKeywords", urlString);

                        this.finalUrlKeywords = urlString;

                        this.getGroupingKeywordsAttachedFinalUrl();
                    } else {
                        this.$toast.error('the response is empty', {
                            position: "top-right",
                            autoClose: 5000,
                        });
                    }

                }

            } catch (error) {
                console.log(error);
            }
        },

        async getGroupingKeywordsAttachedFinalUrl() {
            try {
                this.setUrlGroupingKeywordsLoading = true;
                this.requestSteps.groupingKeywords = false;
                this.requestSteps.groupingKeywordsPrompt = false;
                this.requestSteps.keywords = false;
                this.requestSteps.avoidCompetitors = false;
                this.requestSteps.avoidKeywords = false;
                this.requestSteps.showUserSuggestedKeywords = false;
                this.requestSteps.userSuggestedKeywords = false;
                this.requestSteps.userDomain = false;
                this.requestSteps.groupingKeywords = false;
                this.requestSteps.groupingKeywordsPrompt = false;
                this.requestSteps.keywords = false;
                this.requestSteps.avoidCompetitors = false;
                this.requestSteps.avoidKeywords = false;
                this.requestSteps.showUserSuggestedKeywords = false;
                this.requestSteps.userSuggestedKeywords = false;
                this.requestSteps.userDomain = false;
                this.requestSteps.scrapingUrl = true;
                this.question.scrapingUrl = this.getFinalUrlKeywords;
                var ai_response = await this.processAIResponse(this.question, this.requestSteps);

                if (ai_response.currentStep == 'scrapingUrl') {

                    this.setUrlGroupingKeywordsLoading = false;

                    var response = ai_response.AIData;

                    this.extractedFinalUrlLinksHTML = response;

                    this.$store.dispatch("setFinalUrlGroupingKeyWords", response);
                    this.finalUrlGroupingKeywords = true;
                    this.storeGroupingKeywords()
                }
            }
            catch (error) {
                console.log(error);
            }
        },

        //store company to backend
        async storeCompany() {
            try {
                var response = await axios.post(`${this.apiUrl}api/companies/add-company/`, {
                    'company_name': this.question.companyName,
                    'user_keywords': this.question.keywords,
                    'user_id': this.user_id,
                });

                if (response.status === 200) {
                    console.log(response.data)
                    this.$store.dispatch("setCompany", response.data.company_id)
                    this.company_id = response.data.company_id;
                    this.question.companyName = response.data.companies;
                    this.companyName = true;
                    this.disableInputs.companyName = true;
                }

            } catch (error) {
                this.$swal
            }


        },

        // get company form backend
        async getCompany() {
            try {
                var response = await axios.get(`${this.apiUrl}api/companies/get-company/`, {
                    params: {
                        user_id: this.user_id,
                    },
                });

                if (response.status === 200 && response.data.companies.length > 0) {
                    var backendCompany = response.data.companies
                    this.companies = backendCompany;

                    var companyArray = this.companies.filter((company) => {
                        this.company_id = company.id;
                        this.$store.dispatch("setCompany", this.company_id)
                        this.disableInputs.companyName = true;
                        this.question.companyName = company.name;
                    });
                    companyArray.length > 0 ? this.companyName = true : this.companyName = false;
                }

            } catch (error) {
                this.$swal
            }
        },

        //store competitors to backend 
        async storeCompetitors() {
            try {
                var response = axios.post(`${this.apiUrl}api/competitors/add-competitors/`, {
                    'user_id': this.user_id,
                    'company_id': this.company_id,
                    'competitors': this.getCompetitors,
                });

                if (response.data && response.data.status === 200) {
                    this.$toast.success('competitors stored successfully', {
                        position: "top-right",
                        autoClose: 5000,
                    });

                }

            } catch (error) {
                console.log(error)
            }
        },

        // get competitor form the backend
        async getCompetitorsFromBacked() {
            try {
                var response = await axios.get(`${this.apiUrl}api/competitors/get-competitors/`, {
                    params: {
                        user_id: this.user_id,
                        company_id: this.company_id,
                    },
                });

                if (response.status === 200 && response.data.competitors.length > 0) {
                    var backendCompetitors = response.data.competitors
                    this.generatedCompetitors = backendCompetitors;
                    this.apiCompetitors = true;
                    this.disableInputs.nextCompetitorsButtonDisable = true;
                }

            } catch (error) {
                this.$swal
            }
        },

        //store keywords to backend
        async storeKeywords() {
            try {
                var response = axios.post(`${this.apiUrl}api/keywords/add-keywords/`, {
                    'user_id': this.user_id,
                    'company_id': this.company_id,
                    'keywords': this.getAiData,
                });

                if (response.status == 200 && response.data.status === 200) {
                    this.$toast.success('keywords stored successfully', {
                        position: "top-right",
                        autoClose: 5000,
                    });
                }

            } catch (error) {
                this.$swal
            }
        },

        // get keywords from backend
        async getKeywords() {
            try {
                var response = await axios.get(`${this.apiUrl}api/keywords/get-keywords/`, {
                    params: {
                        user_id: this.user_id,
                        company_id: this.company_id,
                    },
                });
                if (response.status === 200 && response.data.keywords.length > 0) {
                    var backendKeywords = response.data.keywords
                    this.generatedKeywords = backendKeywords;
                    this.aiKeywords = true;
                    this.disableInputs.nextKeywordButtonDisable = true;
                }

            } catch (error) {
                console.log(error)
            }
        },

        //store grouping keywords to backend
        async storeGroupingKeywords() {
            try {
                var response = await axios.post(`${this.apiUrl}api/grouping-keywords/add-grouping-keywords/`, {
                    'user_id': this.user_id,
                    'company_id': this.company_id,
                    'grouping_keywords': this.getGroupingKeywords,
                    'domain': this.question.webDomain,
                });

                if (response.status == 200 && response.data.grouping_keywords.length > 0) {
                    // this.$store.dispatch("setGroupingKeywords", response.data.grouping_keywords);
                    // this.backendGroupingKeywords = response.data.grouping_keywords;
                    this.storeAddGroupFinalUrl();
                }

            } catch (error) {
                this.$swal
            }
        },

        // get grouping keywords from backend
        async getGroupingKeywordsBackend() {
            try {
                var response = await axios.get(`${this.apiUrl}api/grouping-keywords/get-grouping-keywords/`, {
                    params: {
                        user_id: this.user_id,
                        company_id: this.company_id,
                    },
                });

                if (response.status === 200 && response.data.grouping_keywords.length > 0) {
                    this.groupingKeywordsSections = true;
                }

            } catch (error) {
                console.log(error)
            }

        },

        //store grouping final url to backend
        async storeAddGroupFinalUrl() {
            try {
                var response = await axios.post(`${this.apiUrl}api/add-groups/add-final-url/`, {
                    'user_id': this.user_id,
                    'company_id': this.company_id,
                    'final_url': this.getFinalUrlGroupingKeyWords,
                });

                if (response.status == 200 && response.data.add_groups.length == 0) {
                    this.$swal.fire(
                        'Error!',
                        "please domain name is incorrect try again",
                        'error',
                    )
                    this.finalUrlGroupingKeywords = false;
                }

                if (response.status === 200 && response.data.add_groups.length > 0) {
                    var result = '';
                    response.data.add_groups.forEach((group, index) => {
                        let groupName = group.group_name;
                        let url = group.final_url;
                        var count = index + 1;
                        result += count + "." + `<h5>${groupName}</h5>:\n <p>${url}</p>`;
                    });
                    this.$store.dispatch("setFinalUrlGroupingKeyWords", result);
                    this.finalUrlGroupingKeywords = true;
                    this.choseTheRelevantPage = result;
                    this.campaignNameRequestAi();
                }
            } catch (error) {
                this.$swal
            }
        },

        //get grouping final url from backend
        async getAddGroupingFinalUrls() {
            try {
                var response = await axios.get(`${this.apiUrl}api/add-groups/get-final-url/`, {
                    params: {
                        user_id: this.user_id,
                        company_id: this.company_id,
                    },
                });

                if (response.status === 200 && response.data.add_groups.length > 0) {

                    var result = '';
                    response.data.add_groups.forEach((group, index) => {
                        let groupName = group.group_name;
                        let url = group.final_url;
                        var count = index + 1;
                        result += `<h5 class='mb-0'> ${count}.${groupName} :</h5>\n <p>${url}</p>`;
                    });

                    this.finalUrlGroupingKeywords = true;
                    this.extractedFinalUrlLinksHTML = result;

                }
            } catch (error) {
                console.log(error)
            }
        },

        async campaignNameRequestAi() {
            this.requestSteps.scrapingUrl = false;
            this.requestSteps.competitors = false;
            this.requestSteps.avoidCompetitors = false;
            this.requestSteps.keywords = false;
            this.requestSteps.avoidKeywords = false;
            this.requestSteps.showUserSuggestedKeywords = false;
            this.requestSteps.campaignName = true;
            this.question.companyName = this.getFinalUrlGroupingKeyWords;
            var ai_response = await this.processAIResponse(this.question, this.requestSteps);
            try {
                if (ai_response.currentStep == 'campaignName') {

                    try {
                        const responseObject = JSON.parse(ai_response.AIData);
                        const campaignName = responseObject.campaign_name;
                        if (campaignName) {
                            this.$store.dispatch("setCampaignName", campaignName);
                            this.storeCampaign();
                        }
                    } catch (error) {
                        console.error("Error parsing JSON:", error);
                    }

                }
            } catch (error) {
                console.log(error, "catch error")
            }
        },

        async storeCampaign() {
            try {
                var response = await axios.post(`${this.apiUrl}api/campaigns/add-campaign/`, {
                    'user_id': this.user_id,
                    'company_id': this.company_id,
                    'campaign_name': this.getCampaignName,
                });

                if (response.status == 200 && response.data.campaign.length == 0) {
                    this.$swal.fire(
                        'Error!',
                        " Something went wrong!",
                        'error'
                    )
                }
            } catch (error) {
                this.$swal.fire(
                    'Error!',
                    'Something went wrong!',
                    'error'
                )
            }
        },

        //get Campaign from backend 

        async getCampaign() {
            try {
                var response = await axios.get(`${this.apiUrl}api/campaigns/get-campaign/`, {
                    params: {
                        user_id: this.user_id,
                        company_id: this.company_id,
                    },
                });
                if (response.status == 200 && response.data.campaigns.length > 0) {
                    console.log(response.data.campaign)
                }
            } catch (error) {
                console.log(error);
            }
        }
    },

    computed: {
        ...mapGetters({
            getCompanyName: "getCompanyName",
            getAiData: "getAiData",
            getCompetitors: "getCompetitors",
            getGroupingKeywords: "getGroupingKeywords",
            getCompetitorsPrompt: "getCompetitorsPrompt",
            getKeywordsPrompt: "getKeywordsPrompt",
            getGroupingKeywordsPrompt: "getGroupingKeywordsPrompt",
            getUserDomainData: "getUserDomainData",
            getUserDomainPrompt: "getUserDomainPrompt",
            getFinalUrlKeywords: "getFinalUrlKeywords",
            getFinalUrlGroupingKeyWords: "getFinalUrlGroupingKeyWords",
            getCampaignName: "getCampaignName",
        }),

    },


    mounted() {
        this.isLoading = true;
        setTimeout(() => {
            this.isLoading = false;
        }, 500);
        this.companyName = true;
        this.convertKeywordStringToArray(this.getAiData);
        this.convertKeywordStringToArray(this.getCompetitors);
        this.getFinalUrlKeywords = this.finalUrlKeywords;
        this.choseTheRelevantPage = this.getFinalUrlGroupingKeyWords;
        this.getCompany();
        this.getCompetitorsFromBacked();
        this.getKeywords();
        this.question.companyName = this.getCompanyName.name;
        this.getGroupingKeywords = this.groupingKeywords;
        this.generatedGroupingKeywords = this.getGroupingKeywords;
        this.company_id = this.getCompanyName;
        this.backendGroupingKeywords = this.getGroupingKeywords;
        this.getGroupingKeywordsBackend()
        this.getAddGroupingFinalUrls();
        this.getCampaign();
        console.log(this.question.webDomain, "this.question.userDomain")

    },


}
</script>
<style>
.sidebar-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 20%;
    background: #161B22;
    color: #fff;
    height: 100%;
    transition: all 0.3s ease;
    border-right: 1px solid #21262D !important
}

.right-sidebar-container {
    position: absolute;
    top: 0;
    right: 0;
    width: 20%;
    background: #161B22;
    color: #fff;
    height: auto;
    transition: all 0.3s ease;
}

.img {
    cursor: pointer;
}

.sidebar-toggle {
    width: 0%;
    transition: all 0.3s ease;
    /* height: 0; */
}

.right-sidebar-toggle {
    width: 0%;
    transition: all 0.3s ease;
    /* height: 0; */
}

.right-side-content {
    position: fixed;
    top: 0;
    transition: all 0.3s ease;
    background: #161B22;
    height: 100%;
    overflow-y: scroll;
    border-right: 1px solid #21262D !important;
}

.left-20 {
    left: 20% !important;
}

.visible-scrollbar,
.invisible-scrollbar,
.mostly-customized-scrollbar {
    display: none;
    width: 10em;
    overflow: auto;
    height: 2em;
}

.right-side-content::-webkit-scrollbar {
    display: none;
}

.navbar-toggle {
    width: 100%;
    left: 0;
}

p {
    cursor: pointer;
}

/* #promptInputBox {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 60%;
} */

.minus-left-15 {
    position: relative;
    right: 15% !important;
}

.prompt-search-wrapper {
    position: fixed;
    z-index: 99999;
    bottom: 0;
    background: #161B22;
    width: 100%;
    padding: 30px 0px;
}

.prompt-search-wrapper .row {
    margin: 0 auto;
    width: 80%;
}

.left-11 {
    left: 11%;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #6d747b !important;
    opacity: 1;
}

.text-deleted {
    text-decoration: line-through !important;
    color: #8B949E !important;
    /* opacity: 0.2; */
}

.form-control::placeholder {
    color: #fff !important;
}
</style>   
