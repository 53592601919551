import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

//Vue.use(Vuex);

const state = {
  setCompany: "",
  aiData: [],
  competitors: [],
  setGroupingKeywords: [],
  setCompetitorsPrompt: "",
  setAvoidCompetitorsPrompt: "",
  setKeywordsPrompt: "",
  setAvoidKeywordsPrompt: "",
  setGroupingKeywordsPrompt: "",
  setDomain: "",
  userDomainData: "",
  setUserDomainPrompt: "",
  setFinalUrlKeywords: [],
  setFinalUrlGroupingKeyWords: "",
  setCampaignName: "",
};

const store = new Vuex.Store({
  state,
  getters: {
    getCompanyName: (state) => {
      return state.setCompany;
    },
    getAiData: (state) => {
      return state.aiData;
    },
    getCompetitors: (state) => {
      return state.competitors;
    },
    getGroupingKeywords: (state) => {
      return state.setGroupingKeywords;
    },
    getDomain: (state) => {
      return state.setDomain;
    },
    getCompetitorsPrompt: (state) => {
      return state.setCompetitorsPrompt;
    },
    getAvoidCompetitorsPrompt: (state) => {
      return state.setAvoidCompetitorsPrompt;
    },
    getKeywordsPrompt: (state) => {
      return state.setKeywordsPrompt;
    },
    getAvoidKeywordsPrompt: (state) => {
      return state.setAvoidCompetitorsPrompt;
    },
    getGroupingKeywordsPrompt: (state) => {
      return state.setGroupingKeywordsPrompt;
    },
    getUserDomainData: (state) => {
      return state.userDomainData;
    },
    getUserDomainPrompt: (state) => {
      return state.setUserDomainPrompt;
    },
    getFinalUrlKeywords: (state) => {
      return state.setFinalUrlKeywords;
    },
    getFinalUrlGroupingKeyWords: (state) => {
      return state.setFinalUrlGroupingKeyWords;
    },

    getCampaignName: (state) => {
      return state.setCampaignName;
    },
  },

  actions: {
    setCompany: (context, setCompany) => {
      context.commit("setCompany", setCompany);
    },
    setAiData: (context, aiData) => {
      context.commit("setAiData", aiData);
    },
    setCompetitors: (context, competitors) => {
      context.commit("setCompetitors", competitors);
    },
    setGroupingKeywords: (context, setGroupingKeywords) => {
      context.commit("setGroupingKeywords", setGroupingKeywords);
    },
    setDomain: (context, setDomain) => {
      context.commit("setDomain", setDomain);
    },
    setCompetitorsPrompt: (context, setCompetitorsPrompt) => {
      context.commit("setCompetitorsPrompt", setCompetitorsPrompt);
    },
    setAvoidCompetitorsPrompt: (context, setAvoidCompetitorsPrompt) => {
      context.commit("setAvoidCompetitorsPrompt", setAvoidCompetitorsPrompt);
    },
    setKeywordsPrompt: (context, setKeywordsPrompt) => {
      context.commit("setKeywordsPrompt", setKeywordsPrompt);
    },
    setAvoidKeywordsPrompt: (context, setAvoidKeywordsPrompt) => {
      context.commit("setAvoidKeywordsPrompt", setAvoidKeywordsPrompt);
    },
    setGroupingKeywordsPrompt: (context, setGroupingKeywordsPrompt) => {
      context.commit("setGroupingKeywordsPrompt", setGroupingKeywordsPrompt);
    },

    setUserDomainData: (context, userDomainData) => {
      context.commit("setUserDomainData", userDomainData);
    },
    setUserDomainPrompt: (context, setUserDomainPrompt) => {
      context.commit("setUserDomainPrompt", setUserDomainPrompt);
    },

    setFinalUrlKeywords: (context, setFinalUrlKeywords) => {
      context.commit("setFinalUrlKeywords", setFinalUrlKeywords);
    },

    setFinalUrlGroupingKeyWords: (context, setFinalUrlGroupingKeyWords) => {
      context.commit(
        "setFinalUrlGroupingKeyWords",
        setFinalUrlGroupingKeyWords
      );
    },

    setCampaignName: (context, setCampaignName) => {
      context.commit("setCampaignName", setCampaignName);
    },
  },

  mutations: {
    setCompany: (state, setCompany) => {
      state.setCompany = setCompany;
    },
    setAiData: (state, aiData) => {
      state.aiData = aiData;
    },
    setCompetitors: (state, competitors) => {
      state.competitors = competitors;
    },
    setGroupingKeywords: (state, setGroupingKeywords) => {
      state.setGroupingKeywords = setGroupingKeywords;
    },
    setDomain: (state, setDomain) => {
      state.setDomain = setDomain;
    },
    setCompetitorsPrompt: (state, setCompetitorsPrompt) => {
      state.setCompetitorsPrompt = setCompetitorsPrompt;
    },
    setAvoidCompetitorsPrompt: (state, setAvoidCompetitorsPrompt) => {
      state.setAvoidCompetitorsPrompt = setAvoidCompetitorsPrompt;
    },
    setKeywordsPrompt: (state, setKeywordsPrompt) => {
      state.setKeywordsPrompt = setKeywordsPrompt;
    },
    setGroupingKeywordsPrompt: (state, setGroupingKeywordsPrompt) => {
      state.setGroupingKeywordsPrompt = setGroupingKeywordsPrompt;
    },

    setUserDomainData: (state, userDomainData) => {
      state.userDomainData = userDomainData;
    },
    setUserDomainPrompt: (state, setUserDomainPrompt) => {
      state.setUserDomainPrompt = setUserDomainPrompt;
    },
    setFinalUrlKeywords: (state, setFinalUrlKeywords) => {
      state.setFinalUrlKeywords = setFinalUrlKeywords;
    },
    setFinalUrlGroupingKeyWords: (state, setFinalUrlGroupingKeyWords) => {
      state.setFinalUrlGroupingKeyWords = setFinalUrlGroupingKeyWords;
    },

    setCampaignName: (state, setCampaignName) => {
      state.setCampaignName = setCampaignName;
    },
  },
  plugins: [createPersistedState()],
});

export default store;
