import OpenAI from "openai";

export default {
  data() {
    return {
      AiDAta: [],
    };
  },
  methods: {
    async processAIResponse(data, requestSteps) {
      var prompt = "";
      var previousKeywords = this.$store.getters.getAiData;
      var previousCompetitors = this.$store.getters.getCompetitors;
      var previousGroupingKeywords = this.$store.getters.getGroupingKeywords;
      var previousAddGroupingKeywords =
        this.$store.getters.getFinalUrlGroupingKeyWords;
      var currentStep = "";

      if (requestSteps.competitors) {
        currentStep = "competitors";
        if (requestSteps.competitorPrompt) {
          prompt = data.competitors;
        } else {
          prompt = `generate 10 competitors for ${data.companyName} based on the following keywords: ${data.keywords} and return a list of 10 competitors.`;
        }
        this.$store.dispatch("setCompetitorsPrompt", prompt);
      }

      if (requestSteps.avoidCompetitors) {
        currentStep = "avoidCompetitors";
        prompt = `In the following list of competitors: ${previousCompetitors} as ${data} are not relevant so replace these with new competitors with same theme and intent.`;
        this.$store.dispatch("setAvoidCompetitorsPrompt", prompt);
      }

      if (requestSteps.keywords) {
        currentStep = "keywords";
        if (requestSteps.keywordsPrompt) {
          prompt = data.keywords;
        } else {
          prompt = `consider the following list of keywords: ${data.keywords} and the following competitors: ${previousCompetitors} and return back 50 keywords with the same theme and intent that will be used in an ad campaign to promote ${data.companyName}.`;
        }
        this.$store.dispatch("setKeywordsPrompt", prompt);
      }

      if (requestSteps.avoidKeywords) {
        currentStep = "avoidKeywords";
        prompt = `consider the following list of keywords: ${previousKeywords} and the following competitors: ${previousCompetitors} and replace the following keywords: ${data} with new keywords that have the same theme and intent as the previous keywords and provide me list of list of 50 keywords including appended new updated keywords.`;
        // prompt = `consider the following list of keywords: ${previousKeywords} and the following competitors: ${previousCompetitors} and replace the following keywords: ${data} with new keywords that have the same theme and intent as the previous keywords.`;
        // this.$store.dispatch("setAvoidKeywordsPrompt", prompt);
      }

      // if (requestSteps.avoidKeywords) {
      //   currentStep = "avoidKeywords";
      //   data.userSuggestedKeywords = data.userSuggestedKeywords
      //     .split(", ")
      //     .map((t) => {
      //       return "'" + t + "',";
      //     })
      //     .join("");

      //   prompt = `Generate a list of 50 keywords related to promoting '${data.companyName}' with the same theme and intent as the provided list of AI-generated keywords are: ${previousKeywords} . Please update keywords ${data.avoidKeywords} while maintaining the theme. You can use ${data.userSuggestedKeywords} as reference terms to create replacement keywords for these positions. Ensure that the avoided keywords and their corresponding numbers are included in the list, and provide suggested keywords for updated alternatives. Keep all other keywords the same`;
      //   this.$store.dispatch("setAvoidKeywordsPrompt", prompt);
      // }

      if (requestSteps.demandKeywords) {
        currentStep = "demandKeywords";
        prompt = `consider these keywords: ${previousKeywords} now generate ${data.keywordDemand} more based on the provided keywords theme and intent and return a merged list of provided keywords and new generated keywords as a whole.`;
        this.$store.dispatch("setDemandKeywordsPrompt", prompt);
      }

      if (requestSteps.groupingKeywords) {
        currentStep = "groupingKeywords";
        if (requestSteps.groupingKeywordsPrompt) {
          prompt = data.groupingKeywords;
        } else {
          var prompt_variable =
            "\n\n1  [groupHeading]:\n  [item1]\n   [item2]\n   [item3]\n\n2  [groupHeading]:\n  [item4]\n   [item5]\n  [item6]\n\n3  [groupHeading]:\n  [item7]\n  [item8]\n  [item9]\n";
          var removeInverted_comma = prompt_variable.replace(/'/g, '"');
          var grouping_format =
            '{"grouping_keywords": [{"groupHeading": ["[item1]", "[item2]", "[item3]"]}, {"groupHeading": ["[item4]", "[item5]", "[item6]"]}, {"groupHeading": ["[item7]", "[item8]", "[item9]"]} ]}';
          var remove_comma_grouping = grouping_format.replace(/'/g, '"');
          prompt = `Now, take the ${previousKeywords} and categorize them into groups of 7-10 keywords each, with a maximum of 15 keywords per group. Provide each group with a name that summarizes the theme and intent of the "group of keywords," and return a list of 3 groups of keywords. The generated response must follow this format: ${removeInverted_comma}. Only 3 groups are allowed in "array of Objects" format. ${remove_comma_grouping} is the only allowed format.`;
          console.log("prompt", prompt);
        }
        this.$store.dispatch("setGroupingKeywordsPrompt", prompt);
      }

      if (requestSteps.scrapingUrl) {
        currentStep = "scrapingUrl";

        let stringData = "";

        previousGroupingKeywords.forEach((group, index) => {
          const group_name = Object.keys(group);
          let count = index + 1;
          stringData += count + "." + group_name + ":\n";

          group[group_name].forEach((keyword) => {
            stringData += "-" + keyword + "\n";
          });
        });

        prompt = `Match each group with the most relevant URL: ${stringData} Provide the most relevant URL from the following list for each group:
        ${data.scrapingUrl}. Generate a response in the format: <h5> [itemNumber] [groupHeading]:</h5> \n <p>[URL]</p>`;
      }

      if (requestSteps.campaignName) {
        currentStep = "campaignName";
        console.log("previousAddGroupingKeywords", previousAddGroupingKeywords);

        prompt = `Take AdGroup ${previousAddGroupingKeywords} and group them into a parent campaign. Provide the campaign name in JSON format: {"campaign_name": "[campaign_name]}".`;
      }

      const GPT35TurboMessage = [
        {
          role: "user",
          content: prompt,
        },
      ];

      const openai = new OpenAI({
        apiKey: process.env.VUE_APP_OPENAI_API_KEY,
        dangerouslyAllowBrowser: true,
      });

      const completion = await openai.chat.completions.create({
        model: process.env.VUE_APP_OPENAI_GPT_MODEL,
        messages: GPT35TurboMessage,
        temperature: parseFloat(process.env.VUE_APP_OPENAI_GPT_TEMPERATURE),
        max_tokens: parseInt(process.env.VUE_APP_OPENAI_GPT_MAX_TOKENS),
        top_p: parseFloat(process.env.VUE_APP_OPENAI_GPT_TOP_P),
        frequency_penalty: 0,
        presence_penalty: 0,
      });
      var AIData = completion.choices[0].message.content;
      console.log("AIData", AIData);
      return { AIData: AIData, currentStep: currentStep };
    },
  },
};
