<template>
    <div class="nav-container w-100">
        <nav :class="checkingSideLayouts()" class="navbar navbar-dark bg-dark navbar-expand-lg border-bottom">
            <div class="container-fluid">
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01"
                    aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
                    <div :class="sidebarToggle == false ? 'py-3' : ''" class="row w-100 d-flex">
                        <div v-if="sidebarToggle"
                            class="col-md-2 navbar-logo-container d-flex align-items-center justify-content-between pe-3">
                            <a class="navbar-brand" href="#">
                                <img src="../assets/nav-logo.png" alt="">
                            </a>
                            <a v-if="sidebarToggle" @click="handleSidebarToggle()" id="sidebar-toggler-btn">
                                <img src="../assets/expand.svg" alt="" style="    margin-right: 300px !important">
                            </a>
                        </div>
                        <div class="col-md-9 d-flex align-items-center">
                            <p class="text-light mb-0 header-title-ai">Title of the conversation</p>
                        </div>
                    </div>
                    <div class="d-flex flex-row align-items-center justify-content-end w-100">
                        <button style="border: 1px solid #8B949E;" class="px-3 me-3 btn  new-chat-button"
                            @click="addNewChangeHandle()">+ New
                            Chat</button>
                        <a v-if="rightSidebarToggle == true" @click="handleRightSidebarToggle()">
                            <img src="../assets/expand.svg" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</template>
<script>
export default {
    name: "NavbarComponent",
    props: ['sidebarToggle', 'rightSidebarToggle'],
    components: {
    },
    data() {
        return {
            title: 'Navbar',
        }
    },
    methods: {
        handleSidebarToggle() {
            this.$emit('changeSidebarToggle');
        },
        handleRightSidebarToggle() {
            this.$emit('changeRightSidebarToggle');
        },
        checkingSideLayouts() {
            if (this.sidebarToggle == false && this.rightSidebarToggle == false) {
                return 'w-60';
            } else if (this.sidebarToggle == true && this.rightSidebarToggle == false) {
                return 'w-80';
            } else if (this.sidebarToggle == false && this.rightSidebarToggle == true) {
                return 'w-80';
            }
        },
        addNewChangeHandle() {
            this.$emit('addNewChange');
        }
    }
}
</script>
<style>
.navbar-logo-container {
    width: 20%;
}

.center-navbar {
    position: fixed;
    width: 60%;
    z-index: 400;
}

.w-80 {
    position: fixed;
    width: 80%;
    z-index: 400;
}

.w-60 {
    position: fixed;
    width: 60%;
    z-index: 400;
}
</style>
